import { createStore, action } from 'easy-peasy';

const MainStore = createStore({
    user: undefined,
    setUser: action((state, payload) => {
        state.user = payload;
    }),
  });
  
  export default MainStore;
